// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-templates-cash-area-equipment-js": () => import("./../../../src/templates/cash-area-equipment.js" /* webpackChunkName: "component---src-templates-cash-area-equipment-js" */),
  "component---src-templates-ceramic-tile-holder-js": () => import("./../../../src/templates/ceramic-tile-holder.js" /* webpackChunkName: "component---src-templates-ceramic-tile-holder-js" */),
  "component---src-templates-index-page-js": () => import("./../../../src/templates/index-page.js" /* webpackChunkName: "component---src-templates-index-page-js" */),
  "component---src-templates-metal-constructions-js": () => import("./../../../src/templates/metal-constructions.js" /* webpackChunkName: "component---src-templates-metal-constructions-js" */),
  "component---src-templates-self-checkout-js": () => import("./../../../src/templates/self-checkout.js" /* webpackChunkName: "component---src-templates-self-checkout-js" */),
  "component---src-templates-shop-equipment-js": () => import("./../../../src/templates/shop-equipment.js" /* webpackChunkName: "component---src-templates-shop-equipment-js" */)
}

